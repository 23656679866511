.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  background: #f4f4f4; /* Light background color */
}

.summaryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjusts number of columns based on container width */
  flex-direction: column;
  gap: 20px;
}

.summaryCard {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for headings */
.summaryCard h2 {
  margin-top: 0;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

/* Removes bullet points for certain lists */
ul.no-bullets {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Styling for list items */
li {
  margin-bottom: 10px;
  line-height: 1.6;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.green-text {
  color: green;
}

/* Bold styling for key metrics */
.number-of-links, 
.number-of-groups {
  font-weight: bold;
}

/* Group name text color */
.group-name {
  color: green;
  font-weight: normal; /* Adjust the font-weight as needed */
}

/* Styles for loading message */
.loading-message {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  text-align: center;
  margin: 20px 0;
}

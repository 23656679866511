body { 
  overflow-y: auto; /*new*/
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #4caf50;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.title {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust for visual centering */
  width: 50%;
}


.footer-chat-selections{
  background-color: #4caf50;
  border-top: 1px solid #ddd;
  padding: 20px 0; /*new*/
  text-align: center;
  flex-shrink: 0;
  position: relative;
  width:100%;
}

.footer-chat-selections .footer-text {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.ChatSelectionsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  overflow-y: auto;
  flex: 1 0 auto; /*new*/
  padding: 20px; /*new*/
}

.ChatSelectionsPage h1 {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.form-container {
  background: white;
  max-height: 65vh;
  width: 30vw;
  overflow-y: auto;
  border: 3px solid #4CAF50;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 450px;
}

.checkbox-container {
  margin-bottom: 20px;
}

.checkbox-container > div {
  margin-bottom: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center; /* Align items by checkbox */
}

.checkbox-item label {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkbox-item input {
  margin-right: 10px; /* Space between checkbox and label text */
}

.toggle-button {
  margin-bottom: 20px; /* Add space between button and checkbox container */
}

.donate-button {
  margin-top: 20px;
  position: relative;
  font-size: 1.1em;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
}

.donate-button:hover {
  background-color: #0056b3;
}

.donate-button.disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.donate-button:not(.disabled):hover {
  background-color: #45a049; /* Slightly darker on hover */
}

/* Progress Bar */
.progress-bar-container {
width: 50vw;
background-color: #ddd;
border-radius: 8px;
}

.progress-bar {
height: 20px;
width: 0%;
background-color: #4caf50;
border-radius: 8px;
transition: width 0.1s ease-in-out;
}

/* the AlertBox box */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 1rem;
  max-width: 400px;
  word-wrap: break-word;
  text-align: left;
}

.warning {
  background-color: #ffc107;
  color: #664d03;
}

.success {
  background-color: #bfebc1;
  color: #2e7231;
}

.warning {
  background-color: #ffc107;
  color: #664d03;
}

.error {
  background-color: #d50000;
  color: white;
}

.info {
  background-color: #c6dce6;
  color: #00567b;
}

/**
* Inspired by Flag Object: http://csswizardry.com/2013/05/the-flag-object/
*/

.flag {
  display: table;
  width: 100%;
}

.icon_cell {
  display: table-cell;
  vertical-align: top;
  padding-right: 24px;
}

.body_cell {
  display: table-cell;
  vertical-align: middle;
  padding-right: 48px;
  width: 100%;
}

.flag__image,
.flag__body {
  display: table-cell;
  vertical-align: middle;
}
.flag--top .flag__image,
.flag--top .flag__body {
  vertical-align: top;
}
.flag--bottom .flag__image,
.flag--bottom .flag__body {
  vertical-align: bottom;
}

.flag__image {
  padding-right: 24px;
}
.flag__image > img {
  display: block;
  max-width: none;
}
.flag--rev .flag__image {
  padding-right: 0;
  padding-left: 24px;
}

.flag__body {
  width: 100%;
}

/**
* Notification Styles
*/
.note {
  position: relative;
  overflow: hidden;
  background-color: #e91e63;
}

.note__text {
  padding-right: 48px;
}

.note__close {
  position: absolute;
  top: 12px;
  right: 12px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.note__close:hover {
  opacity: 1;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: flex-start;
}

.header {
  background-color: #4caf50;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.title {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.content li {
  margin-bottom: 0px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  overflow: auto;
}


.footer-registration {
  background-color: #4caf50;
  /* padding: 10px 20px; */
  border-top: 1px solid #ddd;
  text-align: center;
  flex-shrink: 0;
  position: relative;
  width:100%;
}

.disabledButton {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.infoToggle {
  color: #4caf50;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline; 
  display: inline-block;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.infoToggle:hover {
  text-decoration: none;
}

/* .infoButtonContainer {
  display: inline-block;
} */

.collapsibleText {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  max-height: 250px; 
  transition: max-height 0.3s ease-out;
  box-sizing: border-box;
  overflow-y: auto;

}

.register-page-p {
  text-align: left;
  padding-left: 210px;
}

.outer-bullet {
  list-style-type: disc;
  text-align: left;
  padding-left: 20px;
}

.inner-bullet-50 {
  list-style-position: inside;
  padding-left: 50px;
}

.inner-bullet-200 {
  list-style-position: inside;
  padding-left: 200px;
}

.instructions-content {
  margin-bottom: 10px;
}

/*loading spinner */
.spinner {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid green; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

